import React, { useState } from 'react';

import '../App.css';
import Button from '@mui/material/Button';
import { InputAdornment, Link, TextField, Typography } from '@mui/material';


function SubscribeConfirmed() {

  return (
      <>
          <Typography variant="h4" color="text.primary" align="center">Ура! Рады новым участникам!</Typography>

          <Typography variant="body1" color="text.primary" align="left" style={{marginTop:15, padding:10}}>Спасибо и до встречи!</Typography>

      </>
  );
}

export default SubscribeConfirmed;
