import React from 'react';
import logo from './assets/original_icon.png';
import './App.css';
//import '@fontsource/inter/variable.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"

import Main from "./pages/MainPage"
import Subscribe from "./pages/SubscribePage"
import SubscribeClub from "./pages/SubscribeClubPage"
import About from "./pages/AboutPage"
import DeleteAccount from "./pages/DeleteAccountPage"
import PrivacyPolicy from "./pages/PrivacyPolicy"

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main />,
    },
    {
      path: "/index.html",
      element: <Main />,
    },
    {
      path: "/subscribe",
      element: <Subscribe />,
    },
    {
      path: "/subscribe_club",
      element: <SubscribeClub />,
    },
    {
      path: "/about",
      element: <About />,
    },
    {
      path: "/request-account-delete",
      element: <DeleteAccount />,
    },
    {
      path: "/privacy-policy",
      element: <PrivacyPolicy />,
    },
  ])

  return (
      <RouterProvider router={router} />
  )
}

export default App;
